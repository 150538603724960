/*
    File created: 06/03/2024
    Authors: 
        Erik Josías González Lucas

    Modal to assign a nurse to a patient.
    This file was created to modulate and optimize code from patientForm.tsx
*/

import { NursePriority } from "../../utils/nursePriority";
import { ISelectedPatient } from "../../store/interfaces";
import { URI_API, getHeaders } from "../../utils/config";
import Shift from "../../utils/shiftFunctions";

import { AppState, deletePatientNurse, setPatientNurse } from "../../store/reducer";
import { Box, CircularProgress, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Dispatch } from "redux";

export default function ModalSelectNurse(props: { open: boolean; onClose: Function; onSave: Function }) {
    const selectedPatient: ISelectedPatient = useSelector((state: AppState) => state.selectedPatient);

    const [nurses, setNurses] = useState<any>([]);
    const [filteredNurses, setFilteredNurses] = useState<any>([]);
    const [selectednurse_id, setSelectednurse_id] = useState<string>(selectedPatient.nurse_id ?? "");
    const [selectedNurseName, setSelectedNurseName] = useState<string>("");
    const [searchInput, setSearchInput] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isNursesEmpty, setIsNursesEmpty] = useState<boolean>(false);

    const dispatch: Dispatch<any> = useDispatch();

    const fetchSchedule = async (date: any) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
        const day = String(date.getDate()).padStart(2, '0');

        const dateToSearch = `${year}-${month}-${day}`;
        return await (await fetch(`${URI_API}/work_schedule_name_by_day/${dateToSearch}`, getHeaders())).json();
    } 

    const getNurseInformation = async () => {
        setIsLoading(true);
        const date = new Date();
        const pad = (num: any) => num.toString().padStart(2, '0');
        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1);
        const day = pad(date.getDate()); 

        const midnight = new Date(`${year}-${month}-${day}T00:00:00.000-06:00`);
        const default_departure_time = new Date(`${year}-${month}-${day}T07:30:00.000-06:00`);
        let currentShift, schedulesRes;
        // Night shift flow
        if (date > midnight && date < default_departure_time) {
            const yesterday = new Date(date.getTime() - 86400000);
            schedulesRes = await fetchSchedule(yesterday);
            if (schedulesRes[0].schedule_name.includes("nocturno")) {
                currentShift = Shift.AsOption(Shift.Current(schedulesRes));
            } else {
                schedulesRes = await fetchSchedule(date);
                currentShift = schedulesRes[0].schedule_name.includes("nocturno") 
                ? null
                : Shift.AsOption(Shift.Current(schedulesRes));
            }
        // Non-night shift flow
        } else {
            schedulesRes = await fetchSchedule(date);
            currentShift = Shift.AsOption(Shift.Current(schedulesRes));
        }

        // Get Classification Matrix
        let matrix_res = await (await fetch(`${URI_API}/class_category_matrix`, getHeaders())).json();

        // const today_departure_time = new Date(date.getFullYear() + '-' + ((date.getMonth()) + 1) + '-' + date.getDate() + 'T' + currentShift?.departure_time + ':00.000-06:00');
        const today_departure_time = new Date(`${year}-${month}-${day}T${currentShift?.departure_time}:00.000-06:00`);
        let date_str;
        if(date > midnight && date < today_departure_time && currentShift?.key.toLowerCase().includes("nocturno")) {
            const date_str_raw = new Date(date.getTime() - 86400000);
            const year_raw = date_str_raw.getFullYear();
            const month_raw = String(date_str_raw.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
            const day_raw = String(date_str_raw.getDate()).padStart(2, '0');

            date_str = `${year_raw}-${month_raw}-${day_raw}`;
        } else {
            const year_raw = date.getFullYear();
            const month_raw = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
            const day_raw = String(date.getDate()).padStart(2, '0');
            date_str = `${year_raw}-${month_raw}-${day_raw}`;
        }

        let nurses = await (
            await fetch(`${URI_API}/nursing_calendar_sch/${date_str}/${currentShift?.key}/${selectedPatient.area_id}`, getHeaders())
        ).json();

        let availableNurses = NursePriority(schedulesRes.length, selectedPatient, nurses, matrix_res);
        let availableSelectedNurses: any[] = [];
        availableNurses.forEach((nurse: any) => {
            if (
                nurse.classx == "COORD" ||
                nurse.classx == "ENF A" ||
                nurse.classx == "ENF B" ||
                nurse.classx == "ENF C" ||
                nurse.classx == "ENF ESP" ||
                nurse.classx == "ENF SUB" ||
                nurse.classx == "ENF D"
            )
            availableSelectedNurses.push(nurse);
        });

        setNurses(availableSelectedNurses);
        setFilteredNurses(availableSelectedNurses);
        setIsNursesEmpty(availableSelectedNurses.length === 0);
        setIsLoading(false);
    };

    useEffect(() => {
        if (props.open && nurses.length === 0) getNurseInformation();
    }, [props.open]);

    useEffect(() => {
        if (searchInput === "") setFilteredNurses(nurses);
        else {
            setFilteredNurses(
                nurses.filter((nurse: any) => {
                    return nurse.nursename.toLowerCase().includes(searchInput) || nurse.classx.toLowerCase().includes(searchInput);
                })
            );
        }
    }, [searchInput]);

    const buildOptions = (): JSX.Element | JSX.Element[] => {
        if (filteredNurses === undefined) return <></>;

        return filteredNurses.map((nurse: any) => {
            let ntissCountFirstHalf = nurse.patients.filter((patient: any) => patient.categoryid === "CC395F80-1753-4E31-ABE7-DD0EA15B6657").length;
            let tissCountFirstHalf = nurse.patients.filter((patient: any) => patient.categoryid === "9C582510-34A2-4A77-A415-436C95AF6839").length;
            let sumFirstHalf = ntissCountFirstHalf + tissCountFirstHalf;
            let ntissCountSecondHalf = nurse.patients.filter((patient: any) => patient.categoryid === "93F32C9D-9AA3-4672-A594-22D79131B454").length;
            let tissCountSecondHalf = nurse.patients.filter((patient: any) => patient.categoryid === "BC0C92FD-B7EB-4777-B8E9-88981D129667").length;
            let sumSecondHalf = ntissCountSecondHalf + tissCountSecondHalf;
            return (
                <Box className="custom-modal__body__table__row">
                    <Box className="custom-modal__body__table__cell--small">
                        <input
                            type="radio"
                            checked={nurse.nurse_id === selectednurse_id}
                            onClick={() => {
                                setSelectednurse_id(nurse.nurse_id);
                                setSelectedNurseName(nurse.nursename);
                            }}
                        ></input>
                    </Box>
                    <Box className="custom-modal__body__table__cell--big">
                        <h5>{nurse.nursename}</h5>
                    </Box>
                    <Box className="custom-modal__body__table__cell--medium">
                        <h5>{nurse.classx}</h5>
                    </Box>
                    <Box className="custom-modal__body__table__cell--big custom-modal__circles">
                        <Box className="custom-modal__circle custom-modal__circle--purple">
                            {nurse.patients.filter((patient: any) => patient.categoryid === "29404BCA-B6D6-4FD2-A3A8-5A3663CF4552").length}
                        </Box>
                        <Box className="custom-modal__circle custom-modal__circle--cyan">
                            {nurse.patients.filter((patient: any) => patient.categoryid === "B7547881-1699-4A24-B1E1-FDA3C5A8552D").length}
                        </Box>
                        <Box className="custom-modal__circle custom-modal__circle--green">
                            {nurse.patients.filter((patient: any) => patient.categoryid === "A3C112AB-1756-4EC3-B3B6-C241DFD88025").length}
                        </Box>
                        <Box className="custom-modal__circle custom-modal__circle--yellow">
                            {nurse.patients.filter((patient: any) => patient.categoryid === "976C5E6B-0FF5-4FF4-8573-00E342F18E6D").length}
                        </Box>
                        <Box className="custom-modal__circle custom-modal__circle--blue">{sumFirstHalf}</Box>
                        <Box className="custom-modal__circle custom-modal__circle--red">{sumSecondHalf}</Box>
                        <h5>
                            {" "}
                            {nurse.patients.length} paciente
                            {nurse.patients.length > 1 ? "s" : ""}
                        </h5>
                    </Box>
                </Box>
            );
        });
    };

    const _onSave = (): void => {
        dispatch(
            setPatientNurse({
                nurse_id: selectednurse_id,
                nurseName: selectedNurseName,
            })
        );

        setSearchInput("");
        props.onClose();
    };

    const _onCancel = (): void => {
        setSelectednurse_id("");
        setSelectedNurseName("");
        setSearchInput("");

        dispatch(deletePatientNurse());
        props.onClose();
    };

    return (
        <>
            <Modal open={props.open} onClose={() => props.onClose()}>
                <Box className="custom-modal custom-modal--big">
                    <Box className="custom-modal__title">
                        <h4>Selección de Profesional</h4>
                    </Box>
                    <input
                        className="custom-input"
                        type="text"
                        name="search"
                        placeholder="Buscar"
                        onChange={(event) => setSearchInput(event.target.value.toLowerCase())}
                    ></input>
                    <Box className="custom-modal__body">
                        <Box className="custom-modal__body__table__header">
                            <div style={{ width: "8%" }}></div>
                            <h5 className="custom-modal__body__table__cell--big">Profesional</h5>
                            <h5 className="custom-modal__body__table__cell--medium">Categoría</h5>
                            <h5 className="custom-modal__body__table__cell--big">Pacientes</h5>
                        </Box>
                        <Box className="custom-modal__body__table">
                            {isLoading ? (
                                <Box className="custom-modal__center">
                                    <CircularProgress color="secondary" />
                                </Box>
                            ) : isNursesEmpty ? (
                                <Box className="custom-modal__center">No hay personal disponible. Revisa el ajuste de asistencia.</Box>
                            ) : (
                                buildOptions()
                            )}
                        </Box>
                        <Box className="custom-modal__body__buttons">
                            <button className="custom-modal__body__buttons--cancel" onClick={_onCancel}>
                                Cancelar
                            </button>
                            {!isNursesEmpty && (
                                <button className="custom-modal__body__buttons--accept" onClick={_onSave}>
                                    Aceptar
                                </button>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}

import AssistanceAdjustNurses from "../../widgets/assistanceAdjustNurses/assistanceAdjustNurses";
import ReplaceNurseModal from "../../widgets/replaceNurseModal/replaceNurseModal";
import DeleteNurseModal from "../../widgets/deleteNurseModal/deleteNurseModal";
import MoveNurseModal from "../../widgets/moveNurseModal/moveNurseModal";
import { ReactComponent as CrossIcon } from "../../assets/icons/x.svg";
import AddNurseModal from "../../widgets/addNurseModal/addNurseModal";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { useState, useEffect, createContext } from "react";
import HeaderComponent from "../../widgets/header/header";
import { AssistanceAdjustCalendar } from "../../widgets";
import { URI_API, getHeaders } from "../../utils/config";
import { AnimatePresence, motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import { ILoggedUser } from "../../store/interfaces";
import { AppState } from "../../store/reducer";
import Shift from "../../utils/shiftFunctions";
import { useSelector } from "react-redux";
import { Box, Modal } from "@mui/material";
import "./assistanceAdjustView.scss";
import moment from "moment";

export const AssitanceAdjustViewContext = createContext<any>({
    calendarDays: [],
    setCalendarDays: () => {},
    nursesByDay: [],
    nurses: [],
    setNurses: () => {},
    selectedDate: {},
    setSelectedDate: () => {},
    selectedShift: {},
    setSelectedShift: () => {},
    shifts: [],
    availableNurses: {},
    setAvailableNurses: () => {},
    availableAreas: {},
    setAvailableAreas: () => {},
    selectedNurse: "",
    setSelectedNurse: () => {},
    selectedNurses: [],
    setSelectedNurses: () => {},
    selectedArea: "",
    setSelectedArea: () => {},
    onItemSelected: () => {},
    selectedReason: "",
    setSelectedReason: () => {},
    replacementNurse: "",
    setReplacementNurse: () => {},
});

export default function AssistanceAdjustView() {
    const [selectedNurse, setSelectedNurse] = useState<any>("");

    const [selectedNurses, setSelectedNurses] = useState([]);
    const [selectedArea, setSelectedArea] = useState<any>("");
    const [selectedDate, setSelectedDate] = useState<any>(new Date());
    const [nursesByDay, setNursesByDay] = useState<any>([]);
    const [showModal, setShowModal] = useState(false);
    const [modalHeader, setModalHeader] = useState("Agregar Profesional");
    const [showSidebar, setShowSidebar] = useState(false);

    const getNursesByDayMonth = (date: any) => {
        const year_month = date.toISOString().slice(0, 7);
        fetch(`${URI_API}/nursing_calendar_month/${year_month}`, getHeaders())
            .then((res) => res.json())
            .then((body) => {
                let _nursesByDay = body.reduce((acc: any, curr: any) => {
                    let date_key = curr["date"];
                    if (!acc[date_key]) acc[date_key] = [];
                    acc[date_key].push({
                        schedule: curr["schedule"],
                        value: curr["value"],
                    });
                    return acc;
                }, {});
                setNursesByDay(_nursesByDay);
            });
    };

    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const [availableNurses, setAvailableNurses] = useState<any>([]);
    const [nurses, setNurses] = useState<any>([]);
    const [availableAreas, setAvailableAreas] = useState<any>([]);
    const [shifts, setShifts] = useState<any>([]);
    const [selectedShift, setSelectedShift] = useState<any>([]);
    const [duplicateNurse, setDuplicateNurse] = useState(false);
    const monthsL = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ];

    const [calendarDays, setCalendarDays] = useState<any>([]);
    const [selectedReason, setSelectedReason] = useState<any>("");
    const [replacementNurse, setReplacementNurse] = useState("");

    const loggedUser: ILoggedUser = useSelector((state: AppState) => state.loggedUser);

    useEffect(() => {
        let _date = new Date();
        _date.setDate(1);
        _date.setHours(1, 0, 0, 0);
        setSelectedMonth(_date);
        getNursesByDayMonth(_date);
        setCalendarDays(getDaysInMonth(_date));

        fetch(`${URI_API}/nurses`, getHeaders())
            .then((res) => res.json())
            .then((body) => setAvailableNurses(body));

        fetch(`${URI_API}/all_areas`, getHeaders())
            .then((res) => res.json())
            .then((body) => setAvailableAreas(body));

        const schedules_endpoint = loggedUser.position == "nura_super" 
        ? "all_work_schedule_names" 
        : "work_schedule_names"

        // Obtain all Work Schedule (Shifts)
        fetch(`${URI_API}/${schedules_endpoint}`, getHeaders())
            .then((res) => res.json())
            .then((body) => {
                let shifts = body;
                // let currentShift = Shift.Current(shifts);
                shifts = shifts.map((shift: any) => Shift.AsOption(shift));
                // currentShift = Shift.AsOption(currentShift);
                setShifts(shifts);
                // setSelectedShift(currentShift);
                // refreshNursesDataList(currentShift);
            });

        fetch(`${URI_API}/current_work_schedule_name`, getHeaders())
            .then((res) => res.json())
            .then((body) => {
                let currentShift = Shift.Current(body);
                currentShift = Shift.AsOption(currentShift);
                if (currentShift === null) {
                    currentShift = { key: "matutino a" };
                }
                setSelectedShift(currentShift);
                refreshNursesDataList(currentShift);
            });
    }, []);

    useEffect(() => {
        refreshNursesDataList(selectedShift);
    }, [selectedDate, selectedShift]);

    const refreshNursesDataList = (shift: any) => {
        let date = selectedDate;
        if (shift !== null) {
            fetch(
                `${URI_API}/nursing_calendar/${date
                    .toISOString()
                    .slice(0, 10)}/${shift.key}`,
                getHeaders()
            )
                .then((res) => res.json())
                .then((body) => {
                    setNurses(body);
                });
        }
    };

    const onItemSelected = async (item: any) => {
        let isNurseDuplicated = false;
        for (let i = 0; i < nurses.length; i++) {
            if (nurses[i].nurse_id === item.id) {
                isNurseDuplicated = true;
            }
        }
        if (isNurseDuplicated) {
            toast("Este profesional ya existe en el turno.", {
                type: "info",
                containerId: "AAV",
            });
        }
        setDuplicateNurse(isNurseDuplicated);
        setSelectedNurse(item.id);
    };

    const clearFields = () => {
        setSelectedNurse("");
        setSelectedArea("");
        setSelectedArea("");
        setSelectedReason("");
        setReplacementNurse("");
        setDuplicateNurse(false);
        setShowModal(false);
    };

    const requiredFieldToast = () => {
        toast("Favor de llenar todos los campos.", {
            type: "error",
            containerId: "AAV",
        });
    };

    const createNurse = () => {
        if (selectedNurse == "" || selectedArea == "") {
            requiredFieldToast();
            return;
        }

        if (duplicateNurse === true) {
            fetch(
                `${URI_API}/nursing_calendar_duplicate`,
                getHeaders("POST", {
                    date: selectedDate.toISOString().slice(0, 10),
                    nurse_id: selectedNurse,
                    schedulename: selectedShift.key,
                    area_id: selectedArea,
                })
            )
            .then((res) => {
                if(res.status === 200) {
                    refreshNursesDataList(selectedShift);
                    toast("Profesional duplicado añadido exitosamente.", {
                        type: "success",
                        containerId: "AAV",
                    });
                    clearFields();
                } else if(res.status === 406) {
                    toast("No se añadió, ya existe el profesional en el área.", {
                        type: "info",
                        containerId: "AAV",
                    });
                    clearFields();
                }
            });
        } else {
            fetch(
                `${URI_API}/nursing_calendar`,
                getHeaders("POST", {
                    date: selectedDate.toISOString().slice(0, 10),
                    nurse_id: selectedNurse,
                    schedulename: selectedShift.key,
                    area_id: selectedArea,
                })
            )
                .then((res) => res.json())
                .then((body) => {
                    refreshNursesDataList(selectedShift);
                    toast("Profesional añadido exitosamente.", {
                        type: "success",
                        containerId: "AAV",
                    });
                    clearFields();
                });
        }
    };

    const moveNurse = () => {
        if (selectedReason == "" || selectedArea == "") {
            requiredFieldToast();
            return;
        }

        fetch(
            `${URI_API}/nursing_calendar`,
            getHeaders("PUT", {
                id: selectedNurses[0],
                area_id: selectedArea,
                schedulename: selectedShift.key,
                comment: selectedReason,
            })
        )
            .then((res) => res.json())
            .then((body) => {
                refreshNursesDataList(selectedShift);
                toast("Profesional reubicado exitosamente.", {
                    type: "success",
                    containerId: "AAV",
                });
                clearFields();
                setSelectedNurses([]);
            });
    };

    const replaceNurse = () => {
        if (
            selectedReason == "" ||
            selectedArea == "" ||
            replacementNurse == ""
        ) {
            requiredFieldToast();
            return;
        }

        fetch(
            `${URI_API}/nursing_calendar/replace`,
            getHeaders("POST", {
                replaceid: selectedNurses[0],
                date: nurses.find((e: any) => e.id === selectedNurses[0]).date,
                schedulename: selectedShift.key,
                nurse_id: availableNurses.find(
                    (e: any) => e.id === replacementNurse
                ).id,
                classx: availableNurses.find(
                    (e: any) => e.id === replacementNurse
                ).class,
                area_id: selectedArea,
                comment: selectedReason,
            })
        )
            .then((res) => res.json())
            .then((_) => {
                refreshNursesDataList(selectedShift);
                toast("Profesional reemplazado exitosamente.", {
                    type: "success",
                    containerId: "AAV",
                });
            })
            .catch(() => {
                toast("Error al reemplazar profesional.", {
                    type: "error",
                    containerId: "AAV",
                });
            })
            .finally(() => {
                clearFields();
                setSelectedNurses([]);
            });
    };

    const deleteNurse = () => {
        const fetchs = selectedNurses.map((nurse_id) => {
            fetch(
                `${URI_API}/nursing_calendar`,
                getHeaders("DELETE", {
                    id: nurse_id,
                })
            ).then((res) => res.json());
        });
        Promise.all(fetchs).then(() => {
            let _date = new Date();
            _date.setDate(1);
            _date.setHours(1, 0, 0, 0);
            getNursesByDayMonth(_date);

            const label =
                (selectedNurses.length > 1
                    ? "Profesionales eliminados"
                    : "Profesional eliminado") + " exitosamente.";
            toast(label, {
                type: "success",
                containerId: "AAV",
            });
            refreshNursesDataList(selectedShift);
            clearFields();
            setSelectedNurses([]);
        });
    };

    const ModalHeader = () => {
        return (
            <div className="modal-title-container">
                <p className="modal-title">{modalHeader}</p>
                <button className="actionButtons" onClick={clearFields}>
                    <CrossIcon></CrossIcon>
                </button>
            </div>
        );
    };

    const ModalBody = () => {
        let modalBody = <div></div>;
        switch (modalHeader) {
            case "Agregar Profesional":
                modalBody = AddNurseModal();
                break;
            case "Mover Profesional":
                modalBody = MoveNurseModal();
                break;
            case "Reemplazar Profesional":
                modalBody = ReplaceNurseModal();
                break;
            case "Eliminar Profesional":
                modalBody = <DeleteNurseModal></DeleteNurseModal>;
                break;
        }
        return (
            <div
                style={{
                    padding: "15px",
                    display: "flex",
                    flexDirection: "column",
                    paddingInline: "2vw",
                }}
            >
                {modalBody}
            </div>
        );
    };

    const ModalActionLabel = () => {
        switch (modalHeader) {
            case "Agregar Profesional":
                return "Agregar";

            case "Mover Profesional":
                return "Mover";

            case "Reemplazar Profesional":
                return "Reemplazar";

            case "Eliminar Profesional":
                return "Eliminar";
        }
    };

    const ModalAction = () => {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginBottom: "3vh",
                }}
            >
                <button
                    style={{
                        borderRadius: "16px",
                        border: "0px solid #E6E6E6",
                        backgroundColor: "transparent",
                        color: "#A2A2A2",
                        fontFamily: "Manrope",
                        fontWeight: "700",
                        fontSize: "12px",
                        lineHeight: "16.39px",
                        textAlign: "center",
                        padding: "1% 5% 1% 5%",
                        cursor: "pointer",
                    }}
                    type="button"
                    onClick={clearFields}
                >
                    Cancelar
                </button>
                <button
                    style={{
                        borderRadius: "16px",
                        border: "1px solid #E6E6E6",
                        backgroundColor: "#62098C",
                        color: "#FFFFFF",
                        fontFamily: "Manrope",
                        fontWeight: "700",
                        fontSize: "12px",
                        lineHeight: "16.39px",
                        textAlign: "center",
                        padding: "1% 5% 1% 5%",
                        cursor: "pointer",
                    }}
                    type="button"
                    onClick={() => {
                        switch (modalHeader) {
                            case "Agregar Profesional":
                                createNurse();
                                break;
                            case "Mover Profesional":
                                moveNurse();
                                break;
                            case "Reemplazar Profesional":
                                replaceNurse();
                                break;
                            case "Eliminar Profesional":
                                deleteNurse();
                                break;
                        }
                    }}
                >
                    {ModalActionLabel()}
                </button>
            </div>
        );
    };

    const getDaysInMonth = (_date: any) => {
        let date = new Date(_date);
        let all_days = [];
        const month = date.getMonth();
        date.setDate(1);
        while (date.getMonth() === month) {
            const d = new Date(date);
            if (moment(new Date()).isSame(d, "day")) {
                setSelectedDate(d);
            }
            all_days.push({
                day: d,
                selected: moment(new Date()).isSame(d, "day"),
            });
            date.setDate(date.getDate() + 1);
        }
        return all_days;
    };

    const changeMonth = (move: any) => {
        let date = new Date(selectedMonth);
        date.setMonth(date.getMonth() + move);
        setSelectedMonth(date);
        setCalendarDays(getDaysInMonth(date));
        getNursesByDayMonth(date);
    };

    return (
        <>
            <AssitanceAdjustViewContext.Provider
                value={{
                    calendarDays,
                    setCalendarDays,
                    nursesByDay,
                    nurses,
                    setNurses,
                    selectedDate,
                    setSelectedDate,
                    selectedShift,
                    setSelectedShift,
                    shifts,
                    availableNurses,
                    setAvailableNurses,
                    availableAreas,
                    setAvailableAreas,
                    selectedNurse,
                    setSelectedNurse,
                    selectedNurses,
                    setSelectedNurses,
                    selectedArea,
                    setSelectedArea,
                    onItemSelected,
                    selectedReason,
                    setSelectedReason,
                    replacementNurse,
                    setReplacementNurse,
                }}
            >
                <div>
                    <div className="GeneralContainer">
                        <HeaderComponent showSearch={false}></HeaderComponent>
                        <div className="sectionTitle">
                            Ajustes de Asistencia
                        </div>
                        <div className="mainContainer">
                            <div className="monthContainer">
                                <div className="monthLabel">
                                    {monthsL[selectedMonth.getMonth()] +
                                        " " +
                                        selectedMonth.getFullYear()}
                                </div>
                                <button
                                    className="monthButtons"
                                    style={{ marginLeft: "2%" }}
                                    onClick={() => changeMonth(-1)}
                                >
                                    <FaChevronLeft
                                        style={{
                                            alignSelf: "center",
                                        }}
                                    ></FaChevronLeft>
                                </button>
                                <div className="month-dot"></div>
                                <button
                                    className="monthButtons"
                                    style={{ marginRight: "2%" }}
                                    onClick={() => changeMonth(1)}
                                >
                                    <FaChevronRight
                                        style={{
                                            alignSelf: "center",
                                        }}
                                    ></FaChevronRight>
                                </button>
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                overflow: "hidden",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                }}
                            >
                                <AssistanceAdjustCalendar
                                    showModal={showModal}
                                    showSideBar={showSidebar}
                                    setShowSideBar={(value: boolean) =>
                                        setShowSidebar(value)
                                    }
                                ></AssistanceAdjustCalendar>
                            </div>

                            <AnimatePresence initial={false}>
                                <motion.div
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        paddingTop: "1vh",
                                        paddingLeft: "1vw",
                                    }}
                                    transition={{
                                        duration: 0.5,
                                        type: "tween",
                                    }}
                                    animate={showSidebar ? "open" : "closed"}
                                    variants={{
                                        open: {
                                            x: 0,
                                            width: "100%",
                                        },
                                        closed: {
                                            x: 900,
                                            width: "0%",
                                        },
                                    }}
                                >
                                    <AssistanceAdjustNurses
                                        openShowModal={(option: string) => {
                                            setModalHeader(option);
                                            setShowModal(true);
                                        }}
                                        closeSidebar={() =>
                                            setShowSidebar(false)
                                        }
                                    ></AssistanceAdjustNurses>
                                </motion.div>
                            </AnimatePresence>
                        </div>
                    </div>
                    <Modal
                        id="modal-assistance"
                        open={showModal}
                        onClose={clearFields}
                    >
                        <Box
                            id="modal-assistance-body"
                            sx={{
                                width:
                                    modalHeader === "Eliminar Profesional"
                                        ? "30vw"
                                        : "40vw",
                            }}
                        >
                            <div id="modal-assistance-information">
                                <div>
                                    <ModalHeader></ModalHeader>
                                    <ModalBody></ModalBody>
                                </div>
                                <ModalAction></ModalAction>
                            </div>
                            <div className="tag"></div>
                        </Box>
                    </Modal>
                </div>
                <ToastContainer
                    enableMultiContainer
                    containerId={"AAV"}
                    autoClose={2000}
                    position="top-right"
                ></ToastContainer>
            </AssitanceAdjustViewContext.Provider>
        </>
    );
}
